import React, {useState} from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import {LGHeader, SmHeader} from "../components/typography";
import ArticleGrid from "../components/article-grid";

import "./work.css"

const HorizonatalRule = () => <hr style={{height:"2px"}} className="bg-gray-200 my-4"/>

const sortUpdatedAt = (a,b) => new Date(b.node.updatedAt) - new Date(a.node.updatedAt)

const ContentTypeSection = ({articleList, sectionHeader}) => {
  const [expand, setExpand] = useState(true)
  const toggleExpansion = () => setExpand(!expand)
  const maxHeight = articleList.length * 1000

  return (
    <section>
      <HorizonatalRule/>
      <div className="flex justify-between items-center" onClick={toggleExpansion}>
        <SmHeader className="pt-4">{sectionHeader}</SmHeader>
        <SmHeader className="pt-4 hover:underline cursor-pointer" onClick={toggleExpansion}>
          {expand ? 'Collapse' : 'Expand'}
        </SmHeader>
      </div>
      <div className="accordion-content"
           aria-expanded={expand ? "true" : "false"}
           style={{maxHeight: expand ? `${maxHeight}px`: 0}}>
        <ArticleGrid articles={articleList.sort(sortUpdatedAt).map(({node}) => node)} showAll/>
      </div>
    </section>
  )
}

const BlogIndex = ({location, data}) => {
  // const siteTitle = get(data, 'site.siteMetadata.title') siteTitle isn't working
  const allPosts = get(data, 'allContentfulBlogPost.edges')
  const allSolutions = get(data, 'allContentfulSolutionPage.edges')
  const allArticles = get(data, 'allContentfulArticle.edges')
  const allLinkTrees = get(data, 'allContentfulCustomLinkTree.edges')
  const blogPageData = get(data, 'contentfulBlogsPage')

  return (
    <Layout location={location}>
      <div>
        <Helmet>
          <title>Projects & Articles</title>
        </Helmet>
        <section className="p-8 md:p-16 text-white">
          <LGHeader className="pb-4">{blogPageData.blogArchiveTitle}</LGHeader>
          <ContentTypeSection articleList={[...allPosts, ...allArticles, ...allLinkTrees]} sectionHeader="Campaigns" />
          <ContentTypeSection articleList={allSolutions} sectionHeader="Equity Solutions" />
          <HorizonatalRule/>
        </section>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    contentfulBlogsPage(contentful_id: {eq: "Od5hDeljrmFmi0OXgoaXu"}) {
      blogArchiveTitle
    }
    
    # This filter is a hack because I need a blog post that will include _all_ embed types or the graphql
    # query fails. Seems like a bug on their part to now allow them to be optional.
    # fixme: I think we can use createSchemaCustomization to fix this bs 
    allContentfulBlogPost(filter: {slug: {ne: "richtext-entry-not-visible-do-not-delete"}}) {
      edges {
        node {
          updatedAt
          sys {
            contentType { sys { id } }
          }
          title
          slug
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulSolutionPage {
      edges {
        node {
          sys {
            contentType { sys { id } }
          }
          updatedAt
          title
          slug
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulArticle {
      edges {
        node {
          updatedAt
          sys {
            contentType { sys { id } }
          }
          title
          url
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulCustomLinkTree {
      edges {
        node {
          updatedAt
          sys {
            contentType { sys { id } }
          }
          title
          slug
          overrideUrl
          heroImage {
            fluid(maxWidth: 600, maxHeight: 336, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
